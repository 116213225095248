import React, { useState } from 'react';
import FeedbackForm from './FeedbackForm';

function ContactSection() {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  return (
    <>
      <section className="bg-gray-100 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">Готовы обсудить ваш проект?</h2>
          <p className="text-xl text-gray-600 mb-8">
            Свяжитесь с нами, и мы поможем воплотить ваши идеи в реальность
          </p>
          <button
            onClick={() => setIsFeedbackOpen(true)}
            className="inline-flex items-center px-6 py-3 bg-primary hover:bg-primary-hover text-gray-900 rounded-lg font-medium transition-colors"
          >
            Связаться с нами
          </button>
        </div>
      </section>

      <FeedbackForm
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        isFloating={true}
      />
    </>
  );
}

export default ContactSection;