import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight, Zap, Clock, Award, Settings } from 'lucide-react';
import ContactForm from '../components/ContactForm';

function Home() {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className="space-y-20">
      {/* Hero Section */}
      <section className="relative h-[600px] flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://sun9-78.userapi.com/impg/Zmg40HoEO5s2wMa55EzVvwG0yDB7yuFy9ms-5w/FpdjosNaUWg.jpg?size=1080x1052&quality=95&sign=d5019aafc43c37128dec90bddabf5074&type=album"
            alt="3D Printing"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
          <h1 className="text-5xl font-bold mb-6">Воплощаем идеи!</h1>
          <p className="text-xl mb-8 max-w-2xl" lang="ru">
            Используем 3D печать и гибкий подход в работе для реализации смелых
            задумок
          </p>
          <div className="space-x-4">
            <button
              onClick={() => handleNavigation('/gallery')}
              className="inline-flex items-center px-6 py-3 bg-primary hover:bg-primary-hover text-gray-900 rounded-lg font-medium transition-colors"
            >
              Смотреть работы
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">
          Почему выбирают нас
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
              <Settings className="h-8 w-8 text-primary" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Гибкий подход</h3>
            <p className="text-gray-600">
              Адаптируемся под требования каждого клиента
            </p>
          </div>

          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-secondary/20 mb-4">
              <Zap className="h-8 w-8 text-secondary" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Быстрое выполнение</h3>
            <p className="text-gray-600">
              Оперативная обработка заказов и минимальные сроки производства
            </p>
          </div>

          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
              <Clock className="h-8 w-8 text-primary" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Точность исполнения</h3>
            <p className="text-gray-600">
              Высокая точность печати и внимание к мельчайшим деталям
            </p>
          </div>

          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-secondary/20 mb-4">
              <Award className="h-8 w-8 text-secondary" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Гарантия качества</h3>
            <p className="text-gray-600">
              Предоставляем гарантию на все выполненные работы
            </p>
          </div>
        </div>
      </section>

      {/* Technologies Section */}
      <section className="bg-gray-100 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">
            Наши технологии
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOS0QYGNwMVP5Pjocka_b2xbiNDsPLYurbAg&s"
                alt="SLA Printing"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-4">SLA Печать</h3>
                <p className="text-gray-600 mb-4">
                  Создаем высокоточные модели. Идеально для прототипов,
                  мастер-моделей и выставочных образцов.
                </p>
                <button
                  onClick={() => handleNavigation('/sla-printing')}
                  className="inline-flex items-center text-secondary hover:text-secondary-hover"
                >
                  Подробнее
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTQ02n5cnHClIZE0KfCFPV6OLJzgh45xWGzw&s"
                alt="FDM Printing"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-4">FDM Печать</h3>
                <p className="text-gray-600 mb-4">
                  Создаем прочные функциональные изделия из различных
                  материалов. Отлично подходит для замены сломанных деталей и
                  бюджетного макетирования
                </p>
                <button
                  onClick={() => handleNavigation('/fdm-printing')}
                  className="inline-flex items-center text-secondary hover:text-secondary-hover"
                >
                  Подробнее
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form */}
      <ContactForm
        isOpen={isContactFormOpen}
        onClose={() => setIsContactFormOpen(false)}
      />
    </div>
  );
}

export default Home;