import React from 'react';
import { Users, Target, Lightbulb } from 'lucide-react';
import ContactSection from '../components/ContactSection';
import TeamSection from '../components/TeamSection';

function About() {
  return (
    <div className="space-y-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-6">О нас</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Мы - молодая студия 3D печати, нацеленная на воплощение самых смелых
            идей наших клиентов в реальность. Наша команда объединяет опыт и
            креативность, чтобы предложить наилучшие решения.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
              <Users className="h-8 w-8 text-primary" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Наша команда</h3>
            <p className="text-gray-600">
              Менеджер проектов, оператор печати и дизайнер. Нас не много, но мы
              команда! :)
            </p>
          </div>

          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-secondary/20 mb-4">
              <Target className="h-8 w-8 text-secondary" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Наша миссия</h3>
            <p className="text-gray-600">
              используя аддитивные технологии, предлагать наилучший способ решения
            </p>
          </div>

          <div className="text-center p-6">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/20 mb-4">
              <Lightbulb className="h-8 w-8 text-primary" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Наш подход</h3>
            <p className="text-gray-600">
              Индивидуальное решение каждой задачи и внимание к деталям
            </p>
          </div>
        </div>

        {/* Team Section */}
        <TeamSection />

        <div className="bg-gray-50 rounded-lg p-8 md:p-12 mt-20">
          <h2 className="text-3xl font-bold mb-6 text-center">Наши ценности</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold mb-3">Качество</h3>
              <p className="text-gray-600">
                Мы не идем на компромиссы когда речь идет о качестве наших услуг и
                готовых изделий
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold mb-3">Инновации</h3>
              <p className="text-gray-600">
                Постоянно следим за новыми технологиями и внедряем их в нашу
                работу
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold mb-3">Открытость</h3>
              <p className="text-gray-600">
                Прозрачное ценообразование и честное общение с клиентами
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold mb-3">Развитие</h3>
              <p className="text-gray-600">
                Постоянно совершенствуем наши навыки и процессы работы
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <ContactSection />
    </div>
  );
}

export default About;