// Hero images
export const heroImages = {
  main: '/images/hero/main.jpg',
  sla: '/images/hero/sla.jpg',
  fdm: '/images/hero/fdm.jpg',
};

// Technology images
export const techImages = {
  fdm: '/images/technologies/fdm.jpg',
  sla: '/images/technologies/sla.jpg',
};

// Portfolio images
export const portfolioImages = {
  architectural: [
    'https://ik.imagekit.io/5pc35hfli/Kosha.png?updatedAt=1733489361563',
    'https://images.unsplash.com/photo-1638467611417-e5ef58fc0d3e?auto=format&fit=crop&q=80',
    'https://images.unsplash.com/photo-1638467611417-e5ef58fc0d3e?auto=format&fit=crop&q=80'
  ],
  mechanism: [
    '/images/portfolio/mechanism-1.jpg',
    '/images/portfolio/mechanism-2.jpg',
    '/images/portfolio/mechanism-3.jpg',
  ],
  medical: [
    '/images/portfolio/medical-1.jpg',
    '/images/portfolio/medical-2.jpg',
    '/images/portfolio/medical-3.jpg',
  ],
  industrial: [
    '/images/portfolio/industrial-1.jpg',
    '/images/portfolio/industrial-2.jpg',
    '/images/portfolio/industrial-3.jpg',
  ],
  artistic: [
    '/images/portfolio/artistic-1.jpg',
    '/images/portfolio/artistic-2.jpg',
    '/images/portfolio/artistic-3.jpg',
  ],
  bjd: [
    '/images/portfolio/bjd-1.jpg',
    '/images/portfolio/bjd-2.jpg',
    '/images/portfolio/bjd-3.jpg',
  ],
};