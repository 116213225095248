import React from 'react';
import { Calendar, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';

export const blogPosts = [
  {
    id: 1,
    slug: 'guide-for-3d-printing-beginners',
    title: 'Что учесть перед заказом 3D-печати: гайд для новичков',
    excerpt: 'Полное руководство по подготовке к заказу 3D-печати: от выбора технологии до общения с исполнителем',
    image: 'https://images.unsplash.com/photo-1635002965282-063a6cef6052?auto=format&fit=crop&q=80',
    date: '2024-03-15',
    readTime: '8 мин',
    category: 'Руководства',
  },
  {
    id: 2,
    slug: '3d-printing-vs-traditional',
    title: 'Когда 3D-печать выгоднее литья или фрезеровки?',
    excerpt: 'Сравнение технологий производства и анализ ситуаций, когда 3D-печать становится оптимальным выбором',
    image: 'https://images.unsplash.com/photo-1581092335397-9583eb92d232?auto=format&fit=crop&q=80',
    date: '2024-03-20',
    readTime: '10 мин',
    category: 'Аналитика',
  },
  {
    id: 3,
    slug: 'fdm-for-prototypes',
    title: 'Нужно быстро и недорого? Почему FDM — лучший выбор для прототипов',
    excerpt: 'Узнайте, почему FDM-печать идеально подходит для быстрого прототипирования и тестирования идей',
    image: 'https://images.unsplash.com/photo-1631467987179-c788396c7e5d?auto=format&fit=crop&q=80',
    date: '2024-03-25',
    readTime: '6 мин',
    category: 'Технологии',
  },
  {
    id: 4,
    slug: 'sla-post-processing',
    title: 'Особенности постобработки SLA моделей',
    excerpt: 'Подробное руководство по постобработке моделей, напечатанных по технологии SLA',
    image: 'https://images.unsplash.com/photo-1617038220319-276d3cfab638?auto=format&fit=crop&q=80',
    date: '2024-02-10',
    readTime: '7 мин',
    category: 'Руководства',
  }
];

function Blog() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <h1 className="text-4xl font-bold mb-12 text-center">Блог</h1>

      <div className="grid grid-cols-1 gap-12">
        {blogPosts.map((post) => (
          <article
            key={post.id}
            className="bg-white rounded-lg overflow-hidden shadow-lg flex flex-col md:flex-row"
          >
            <div className="md:w-1/3">
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-64 md:h-full object-cover"
              />
            </div>
            
            <div className="md:w-2/3 p-8">
              <div className="flex items-center space-x-4 text-sm text-gray-500 mb-4">
                <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full">
                  {post.category}
                </span>
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {new Date(post.date).toLocaleDateString('ru-RU')}
                </div>
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {post.readTime}
                </div>
              </div>

              <h2 className="text-2xl font-bold mb-4">{post.title}</h2>
              <p className="text-gray-600 mb-6">{post.excerpt}</p>

              <div className="flex justify-end">
                <Link
                  to={`/blog/${post.slug}`}
                  className="text-blue-600 hover:text-blue-800 font-medium"
                >
                  Читать далее
                </Link>
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}

export default Blog;