import React, { useState, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { portfolioImages } from '../assets/images';
import ContactSection from '../components/ContactSection';
import GalleryFilters from '../components/GalleryFilters';

function Gallery() {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const projects = [
    {
      id: 1,
      title: 'Архитектурный макет',
      description:
        'Детализированный макет жилого комплекса, напечатанный на SLA принтере',
      technology: 'SLA',
      tags: ['SLA', 'painting'],
      images: portfolioImages.architectural,
    },
    {
      id: 2,
      title: 'Прототип механизма',
      description:
        'Функциональный прототип механического устройства из PETG пластика',
      technology: 'FDM',
      tags: ['FDM'],
      images: portfolioImages.mechanism,
    },
    {
      id: 3,
      title: 'Медицинские модели',
      description: 'Анатомические модели для планирования операций',
      technology: 'SLA',
      tags: ['SLA'],
      images: portfolioImages.medical,
    },
    {
      id: 4,
      title: 'Промышленные детали',
      description:
        'Комплект функциональных деталей для производственного оборудования',
      technology: 'FDM',
      tags: ['FDM', 'painting'],
      images: portfolioImages.industrial,
    },
    {
      id: 5,
      title: 'Художественная модель',
      description: 'Высокодетализированная модель с уникальной структурой',
      technology: 'SLA',
      tags: ['SLA', 'painting'],
      images: portfolioImages.artistic,
    },
    {
      id: 6,
      title: 'BJD лиса',
      description: 'Зверек, собранный из множества элементов',
      technology: 'SLA',
      tags: ['SLA', 'painting'],
      images: portfolioImages.bjd,
    },
  ];

  const handleFilterChange = (filter: string) => {
    if (filter === 'all') {
      setActiveFilters([]);
      return;
    }

    setActiveFilters((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((f) => f !== filter);
      }
      return [...prev, filter];
    });
  };

  const filteredProjects = useMemo(() => {
    if (activeFilters.length === 0) return projects;
    return projects.filter((project) =>
      activeFilters.every((filter) => project.tags.includes(filter))
    );
  }, [activeFilters]);

  return (
    <div className="space-y-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <h1 className="text-4xl font-bold mb-12 text-center">Галерея работ</h1>

        <GalleryFilters
          activeFilters={activeFilters}
          onFilterChange={handleFilterChange}
        />

        {filteredProjects.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-600 text-lg">
              По выбранным фильтрам ничего не найдено
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredProjects.map((project) => (
              <div
                key={project.id}
                className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform hover:scale-105"
              >
                <div className="relative">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    navigation={{
                      prevEl: `.prev-${project.id}`,
                      nextEl: `.next-${project.id}`,
                    }}
                    pagination={{ clickable: true }}
                    loop={true}
                    className="h-[250px]"
                  >
                    {project.images.map((image, index) => (
                      <SwiperSlide key={index}>
                        <img
                          src={image}
                          alt={`${project.title} - вид ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <button
                    className={`prev-${project.id} absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-1.5 rounded-full hover:bg-white transition-colors`}
                  >
                    <ChevronLeft className="w-4 h-4" />
                  </button>
                  <button
                    className={`next-${project.id} absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-1.5 rounded-full hover:bg-white transition-colors`}
                  >
                    <ChevronRight className="w-4 h-4" />
                  </button>

                  <div className="absolute top-2 right-2 flex gap-2">
                    {project.tags.map((tag) => (
                      <span
                        key={tag}
                        className="bg-blue-600 text-white px-2 py-0.5 rounded-full text-xs"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="p-4">
                  <h3 className="text-lg font-semibold mb-1">{project.title}</h3>
                  <p className="text-sm text-gray-600">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Contact Section */}
      <ContactSection />
    </div>
  );
}

export default Gallery;