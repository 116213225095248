import emailjs from '@emailjs/browser';

// Initialize EmailJS with your public key
emailjs.init('qevR0RBIq_xvCmzmy');

// Add debounce to prevent multiple rapid submissions
const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const sendEmail = debounce(async (templateParams: any) => {
  try {
    const response = await emailjs.send(
      'service_nnyztis',
      'template_j2huhbr',
      templateParams,
      'qevR0RBIq_xvCmzmy'
    );
    return response;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
}, 500); // 500ms debounce